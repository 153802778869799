import Head from 'next/head';
import { ThemeProvider } from 'next-themes';
import { ErrorBoundary } from 'react-error-boundary';
import { DocumentProvider } from '@context/document-context';
import { ToasterProvider } from '@context/toaster-context';
import { ModalProvider } from '@context/modal-context';
import { ErrorDisplay } from '@components/error-display';
import { inspect } from '@xstate/inspect';
import { startPreviewService } from '@machines/preview.machine';
import { isDevelopment } from '@utils/helpers';
import { initGoogleAnalytics } from '@tracking/ga';
import { useEffect } from 'react';
import { AppProps } from 'next/dist/shared/lib/router/router';
import { global } from '../../stitches.config';

if (process.env.NEXT_PUBLIC_ENABLE_MOCKS === 'true') {
  const mocksReadyPromise = import('../mocks').then(({ initializeMocks }) =>
    initializeMocks(),
  );
  if (typeof window !== 'undefined') {
    // this allows any part of the code to wait for mocks to be ready before making any requests
    window.__mocksReady = mocksReadyPromise;
  }
}

const GlobalStyle = global({
  '*, ::before, ::after': {
    padding: 0,
    margin: 0,
    boxSizing: 'inherit',
    fontFamily: 'IBM Plex Sans, Segoe UI, Tahoma, sans-serif',
  },
  '#__next': {
    height: '100%',
  },
  html: {
    width: '100%',
    height: '100%',
    color: '#001f66',
    '-moz-osx-font-smoothing': 'grayscale',
    '-webkit-font-smoothing': 'antialiased',
    boxSizing: 'border-box',
    lineHeight: '20px',
  },
  body: {
    width: '100%',
    height: '100%',
  },
  '.DraftEditor-root': {
    flex: 1,
  },
});

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  GlobalStyle();

  useEffect(() => {
    if (isDevelopment && process.env.NEXT_PUBLIC_XSTATE) {
      inspect({ iframe: false });
    }
    if (
      (!isDevelopment &&
        !window?.document.location.search.includes(
          process.env.NEXT_PUBLIC_DEMO_PARAM as string,
        )) ||
      (isDevelopment && process.env.NEXT_PUBLIC_GA)
    ) {
      initGoogleAnalytics();
    }
    startPreviewService();
  }, []);

  return (
    <>
      <Head>
        <title>Paperpal - Edit for web</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
      </Head>
      <ThemeProvider attribute="class" defaultTheme="light">
        <ErrorBoundary
          //@ts-expect-error
          FallbackComponent={ErrorDisplay}
          onReset={() =>
            (document.location.href = `/${document.location.search}`)
          }
        >
          <DocumentProvider>
            <ToasterProvider>
              <ModalProvider>
                <Component {...pageProps} />
              </ModalProvider>
            </ToasterProvider>
          </DocumentProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  );
};

export default App;
