/* eslint-disable unicorn/consistent-function-scoping */
import { createCss } from '@stitches/react';

export const { styled, keyframes, css, getCssString, global } = createCss({
  utils: {
    size: (config) => (value) => ({
      width: value,
      height: value,
    }),
    place: (config) => (value) => ({
      top: value,
      left: value,
      bottom: value,
      right: value,
    }),
  },

  theme: {
    colors: {
      mainText: '#333333',
      secondaryText: '#5F7091',
      mainPrimary: '#0060FF',
      secondaryPrimary: '#E6F0FF',
      textContrastPrimary: '#ffffff',
      mainError: '#cc0044',
      secondaryError: '#FAE6ED',
      textContrastError: '#ffffff',
      mainBg: '#ffffff',
      secondaryBg: '#F4F5FB',
    },
    fontSizes: {
      large: '2.4rem',
      regular: '1rem',
      small: '0.9em',
    },
  },
});
