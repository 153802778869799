/* eslint-disable unicorn/prevent-abbreviations */
export enum Category {
  uploadPage = 'upload page',
  paymentPage = 'payment page',
  document = 'document page',
}

export enum Actions {
  uploadIntent = 'UPLOAD_INTENT',
  uploadComplete = 'UPLOAD_COMPLETE',
  processComplete = 'PROCESS_COMPLETE',
  click = 'CLICK',
  paymentIntent = 'PAYMENT_INTENT',
  error = 'ERROR',
  paymentConfirmed = 'PAYMENT_CONFIRMED',
  manuallyFileDownload = 'MANUALLY_FILE_DOWNLOAD',
  autoFileDownload = 'AUTO_FILE_DOWNLOAD',
}

export enum Labels {
  uploadDrag = 'UPLOAD_DRAG',
  uploadCentre = 'UPLOAD_CENTRE',
  uploadTop = 'UPLOAD_TOP',
  uploadComplete = 'UPLOAD_COMPLETE',
  suggestion = 'SUGGESTION',
  suggestionPrev = 'SUGGESTION_PREV',
  suggestionNext = 'SUGGESTION_NEXT',
  getWordFileSidebar = 'GET_WORD_FILE_SIDEBAR',
  getWordFileFooter = 'GET_WORD_FILE_FOOTER',
  downloadLink = 'MODAL_DOWNLOAD_LINK',
  paymentIntentFinal = 'PAYMENT_INTENT_FINAL',
  close = 'CLOSE',
}
