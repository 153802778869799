import { FallbackProps } from 'react-error-boundary';
import { SUPPORT_MAIL } from '@utils/constants';

import { Button } from './button';
import { SadPaperpalLogo } from './logo.component';
import { styled } from '../../stitches.config';

type Props = {
  error?: FallbackProps['error'];
  resetErrorBoundary?: FallbackProps['resetErrorBoundary'];
  heading?: string;
  body?: React.ReactNode;
  actionComponent?: React.ReactNode;
};

export const ErrorDisplay: React.FC<Props> = ({
  resetErrorBoundary,
  heading,
  body,
  actionComponent,
}) => {
  return (
    <Container>
      <Center>
        <SadPaperpalLogo width={100} height={142} />
      </Center>
      <Heading>{heading || 'Oh no! Something went wrong…'}</Heading>
      <Text>
        {body || (
          <>
            It is most likely a temporary issue. Please give us a moment or two
            and try again. <br />
            But if the issue persists, please{' '}
            <a href={`mailto:${SUPPORT_MAIL}`}>reach out to us right here</a>.
          </>
        )}
      </Text>

      {actionComponent || (
        <Center>
          <Button onClick={resetErrorBoundary} variant="outline">
            Try again (🤞)
          </Button>
        </Center>
      )}
    </Container>
  );
};

const Container = styled('div', {
  display: 'grid',
  width: '100%',
  placeContent: 'center',
  rowGap: '1rem',
  paddingTop: '120px',
  textAlign: 'center',
});

const Center = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const Heading = styled('h2', {
  fontWeight: '400',
  fontSize: '1.5rem',
});

const Text = styled('div', {
  color: '$secondaryText',
  marginBottom: '2rem',
});
