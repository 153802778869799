import { styled } from '../../stitches.config';

export type Props = {
  body?: React.ReactNode;
};

export const Toaster: React.FC<Props> = ({ body, children }) => {
  return <StyledToaster>{body || children}</StyledToaster>;
};

const StyledToaster = styled('div', {
  position: 'absolute',
  padding: '0.5rem 1rem',
  backgroundColor: '#051e39',
  color: '#fff',
  fontSize: '1.2rem',
  top: '84px',
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: '3px',
});
