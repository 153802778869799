import React from 'react';

import { Modal, Props as ModalProps } from '../components/modals/modal-core';

type TModalContextType =
  | {
      open: (props: { children: ModalProps['children'] }) => void;
      close: () => void;
    }
  | undefined;

type TModalState = any | null;

const ModalContext = React.createContext<TModalContextType>(undefined);

export const ModalProvider: React.FC = ({ children }) => {
  const [modal, setModal] = React.useState<TModalState>(null);

  const open = React.useCallback((props: any) => {
    setModal(props);
  }, []);

  const close = React.useCallback(() => {
    setModal(null);
  }, []);

  const memoValue = React.useMemo(() => ({ open, close }), [open, close]);

  return (
    <ModalContext.Provider value={memoValue}>
      {children}
      {modal && <Modal onClose={close} {...modal} />}
    </ModalContext.Provider>
  );
};
export const useModal = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
