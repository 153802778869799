import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { styled } from '../../stitches.config';

type Props = {
  spacer?: number;
};

export const Icon: React.FC<FontAwesomeIconProps & Props> = ({
  spacer,
  ...props
}) => {
  return spacer ? (
    <IconContainer
      css={{
        height: `${spacer}px`,
        width: `${spacer}px`,
      }}
    >
      <FontAwesomeIcon {...props} scale="100%" />
    </IconContainer>
  ) : (
    <FontAwesomeIcon fixedWidth {...props} />
  );
};

const IconContainer = styled('div', {
  display: 'grid',
  placeContent: 'center',
});
