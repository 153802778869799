import { styled } from '../../stitches.config';

export const Button = styled('button', {
  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    variant: {
      solid: {
        background: '$$primaryColor',
        color: '$$secondaryColor',
      },
      outline: {
        background: 'transparent',
        borderColor: '$$primaryColor',
        color: '$$primaryColor',
      },
      link: {
        boxShadow: 'none',
        background: 'transparent',
        border: 'none',
        color: '$$primaryColor',
      },
    },
    color: {
      default: {
        $$primaryColor: '#000',
        $$secondaryColor: '#efefef',
      },
      primary: {
        $$primaryColor: '$colors$mainPrimary',
        $$secondaryColor: '$colors$textContrastPrimary',
      },
      error: {
        $$primaryColor: '$colors$mainError',
        $$secondaryColor: '$colors$textContrastError',
      },
    },
    size: {
      small: {},
      regular: {
        padding: '8px 24px',
        height: '48px',
        fontSize: '16px',
        fontWeight: '400',
      },
      large: {},
    },
  },
  defaultVariants: {
    color: 'default',
    variant: 'solid',
    size: 'regular',
  },

  compoundVariants: [
    {
      variant: 'link',
      css: {
        height: 'unset',
        padding: 0,
      },
    },
  ],

  border: '1px solid transparent',
  borderRadius: '8px',
  boxShadow: '0px 2px 8px -4px rgba(10, 35, 92, 0.25)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'transform 0.2s',
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
  '&:focus': {
    outline: 'none',
  },
  '&:active': {
    transform: 'translateY(2px)',
  },
});
